import { useContext, useState, useRef, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ErrorLabel from "../../Forms/ErrorLabel";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import Floater from "react-floater";
import Joyride from "react-joyride";
import EpicidTooltip from "../../UiLibrary/Tooltips/EpicidTooltip";
import DiscordIdTooltip from "../../UiLibrary/Tooltips/DiscordIdTooltip";
import { isMobile } from "react-device-detect";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import Modal from "../../UiLibrary/Modal";
import AddSingleGameAccountModal from "../AddGameAccountModal/AddSingleGameAccountModal";
import ITournament from "../../Interfaces/Tournament";
import IGameAccount from "../../Interfaces/GameAccount";

type Props = {
  tournament: ITournament;
  loading: boolean;
  onConfirm: (data: {
    gameAccounts: Array<{
      userId?: string | undefined;
      gameAccount: IGameAccount | undefined;
    }>;
    password?: string | undefined;
  }) => void;
};

type FormModel = {
  discordId?: string;
  epicId?: string;
};

interface IDisplayValues {
  id: string | undefined;
  game: string | undefined;
  console: string | undefined;
  username: string | undefined;
  displayName?: string;
}

const JoinYuniteTournamentModal = (props: Props) => {
  const { tournament, loading } = props;
  const { user, setUser } = useContext(UserContext);
  const [loadingForm, setLoadingForm] = useState(false);

  const initialValues: FormModel = {
    discordId: user?.discordId,
    epicId: user?.epicId,
  };
  const [passwordType, setPasswordType] = useState("password");
  const [gameAccount, setGameAccount] = useState<IGameAccount>();
  const [showModal, setShowModal] = useState(false);
  const [displayValues, setDisplayValues] = useState<Array<IDisplayValues>>([]);
  const [selectedValue, setSelectedValue] = useState<Array<IDisplayValues>>([]);

  const [showTutorial, setShowTutorial] = useState(true);

  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (user?.gameAccounts && user?.gameAccounts?.length > 0) {
      const gameAccount = user?.gameAccounts?.filter(
        (account: IGameAccount) =>
          tournament?.console.includes(account.console || "") &&
          account.game === tournament?.game
      );
      const displayValues: Array<IDisplayValues> = [...gameAccount];
      displayValues.forEach(
        (account) =>
          (account.displayName = `USERNAME: ${account.username} - PLATFORM: ${account.console}`)
      );
      setDisplayValues(displayValues);
      setSelectedValue([displayValues[0]]);
      setGameAccount(gameAccount[0]);
    }
  }, [tournament, user]);

  const onSubmit = (values: FormModel) => {
    setLoadingForm(true);
    const userId = user?.id;
    UserService.updateUserInfo(values, userId)
      .then((user) => {
        setLoadingForm(false);
        setUser(user);
        toast.success("User information updated");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoadingForm(false);
      });
  };

  const initialSteps = [
    {
      content: <DiscordIdTooltip />,
      floaterProps: {
        disableAnimation: true,
      },
      target: ".skp-help-discord-id",
      placement: "right",
      styles: {
        options: {
          width: isMobile ? "100vw" : 400,
        },
      },
    },
    {
      content: <EpicidTooltip />,
      floaterProps: {
        disableAnimation: true,
      },
      target: ".skp-help-epic-id",
      placement: "left",
      styles: {
        options: {
          width: isMobile ? "100vh" : 400,
        },
      },
    },
    {
      content: <div>Press the button to update the account.</div>,
      floaterProps: {
        disableAnimation: true,
      },
      target: "#update-account-button",
      title: "Update Account",
      placement: "right",
    },
    {
      content: <div>Press the button to join the tournament.</div>,
      floaterProps: {
        disableAnimation: true,
      },
      target: "#join-tournament-button",
      title: "Join Tournament",
      placement: "left",
    },
  ];

  const onSelect = (event: any) => {
    let { displayName, ...newData } = event[0];
    setGameAccount(newData);
  };

  const onRemove = () => {
    setGameAccount(undefined);
  };

  const onConfirm = () => {
    const password =
      tournament?.open === false ? passwordInputRef?.current?.value : undefined;

    let data: {
      gameAccounts: Array<{
        userId?: string | undefined;
        gameAccount: IGameAccount | undefined;
      }>;
      password?: string | undefined;
    } = {
      gameAccounts: [{ userId: user?.id, gameAccount: gameAccount }],
    };
    data.password = password;
    props.onConfirm(data);
  };

  const openModal = () => {
    setShowModal(true);
    setShowTutorial(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowTutorial(true);
  };

  return (
    <div className="container">
      <Joyride
        continuous={true}
        run={showTutorial}
        showSkipButton={true}
        showProgress={true}
        steps={initialSteps as any}
      />
      <div className="skp-text-light text-center">
        You need to complete these steps to Join Tournament.
      </div>
      <div className="space-divider-20"></div>
      <div className="columns">
        <div className="column col-1">
          <span className="skp-btn skp-btn-action">1</span>
        </div>
        <div className="column col-11 pt-2">
          <h6 className="skp-text-primary">
            Add <strong>Discord Id</strong> and <strong>Epic Id</strong>
          </h6>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, touched, isValid }) => (
              <Form className="columns">
                <div className="column col-12">
                  <div className="columns">
                    <div className="column col-5 col-xs-12">
                      {/* Discord Id */}
                      <div
                        className={
                          errors.discordId && touched.discordId
                            ? "skp-form-control skp-form-control-error"
                            : "skp-form-control"
                        }
                      >
                        <label
                          className="skp-text-light skp-text-input-label"
                          htmlFor="discordId"
                        >
                          Discord Id{" "}
                          {!isMobile && (
                            <Floater
                              event="hover"
                              placement="right"
                              content={<DiscordIdTooltip />}
                              styles={{
                                floater: {
                                  zIndex: 9999,
                                },
                              }}
                            >
                              <sup className="skp-help-discord-id">
                                <i className="las la-question-circle"></i>
                              </sup>
                            </Floater>
                          )}
                          {isMobile && (
                            <sup className="skp-help-discord-id">
                              <i className="las la-question-circle"></i>
                            </sup>
                          )}
                        </label>
                        <Field type="text" id="discordId" name="discordId" />
                        <ErrorMessage name="discordId" component={ErrorLabel} />
                      </div>
                    </div>
                    <div className="column col-7 col-xs-12">
                      {/* Epic Id */}
                      <div
                        className={
                          errors.epicId && touched.epicId
                            ? "skp-form-control skp-form-control-error"
                            : "skp-form-control"
                        }
                      >
                        <label
                          className="skp-text-light skp-text-input-label"
                          htmlFor="epicId"
                        >
                          Epic Id{" "}
                          {!isMobile && (
                            <Floater
                              event="hover"
                              placement="left"
                              content={<EpicidTooltip />}
                              styles={{
                                floater: {
                                  zIndex: 9999,
                                },
                              }}
                            >
                              <sup className="skp-help-epic-id">
                                <i className="las la-question-circle"></i>
                              </sup>
                            </Floater>
                          )}
                          {isMobile && (
                            <sup className="skp-help-epic-id">
                              <i className="las la-question-circle"></i>
                            </sup>
                          )}
                        </label>

                        <Field type="text" id="epicId" name="epicId" />
                        <ErrorMessage name="epicId" component={ErrorLabel} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-divider-10"></div>
                <div className="column col-6 col-xs-12">
                  <button
                    type="submit"
                    disabled={loadingForm}
                    id="update-account-button"
                    className="skp-btn skp-btn-primary"
                  >
                    {loadingForm ? (
                      <span className="loading"></span>
                    ) : (
                      "Update Account"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="space-divider"></div>
      </div>
      <div className="columns">
        <div className="column col-1">
          <span className="skp-btn skp-btn-action">2</span>
        </div>
        {/* User Game Accounts */}
        <div className="column col-8 col-xs-12">
          <h6 className="skp-text-primary">Select game account</h6>
          {displayValues.length > 0 && (
            <div className="skp-form-control">
              <Multiselect
                options={displayValues} // Options to display in the dropdown
                selectedValues={selectedValue} // Preselected value to persist in dropdown
                displayValue="displayName" // Property name to display in the dropdown options
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                singleSelect={true}
                closeIcon="cancel"
                placeholder="Select a game account"
                avoidHighlightFirstOption={true}
              />
            </div>
          )}
          {displayValues.length === 0 && (
            <div>
              <div
                className="skp-link-primary skp-text-default"
                onClick={openModal}
              >
                <i className="las la-plus-circle"></i> Add an account for{" "}
                {tournament?.game}
              </div>
              <div className="space-divider-20"></div>
            </div>
          )}
          <p className="skp-text-label skp-text-light">
            To manage your game accounts go to the{" "}
            <Link
              className="skp-link"
              to={`/users/${user?.id}/settings/usersettings`}
            >
              User Setting's page
            </Link>
          </p>
        </div>
        <div className="column col-2 hide-xs"></div>
      </div>
      <div className="columns flex-centered">
        <div className="column col-6 col-xs-12">
          {tournament?.open === false && (
            <div className="skp-form-control">
              <label className="skp-text-label skp-text-light">
                Please provide a password to join.
              </label>
              <input ref={passwordInputRef} type={passwordType} />
              <div className="icon-wrapper">
                {passwordType === "password" && (
                  <i
                    className="lar la-eye skp-text-light"
                    onClick={() => setPasswordType("text")}
                  ></i>
                )}
                {passwordType === "text" && (
                  <i
                    className="lar la-eye-slash skp-text-light"
                    onClick={() => setPasswordType("password")}
                  ></i>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="column col-6 text-right">
          <br></br>
          <button
            id="join-tournament-button"
            className="skp-btn skp-btn-primary"
            disabled={
              (!user?.discordId && !user?.epicId) || loading || !gameAccount
            }
            onClick={onConfirm}
          >
            {loading ? <span className="loading"></span> : "Join Tournament"}
          </button>
        </div>
      </div>

      {/* Add account modal */}
      <Modal
        show={showModal}
        onClose={closeModal}
        title="Add Game Account"
        size="large"
      >
        <AddSingleGameAccountModal
          tournament={tournament}
          closeModal={closeModal}
        ></AddSingleGameAccountModal>
      </Modal>
    </div>
  );
};

export default JoinYuniteTournamentModal;
