import { useState, useRef, useEffect, useContext } from "react";
import Multiselect from "multiselect-react-dropdown";
import GAMES from "../../../constants-games";
import { Link } from "react-router-dom";
import Modal from "../../UiLibrary/Modal";
import AddSingleGameAccountModal from "../AddGameAccountModal/AddSingleGameAccountModal";
import ITournament from "../../Interfaces/Tournament";
import IGameAccount from "../../Interfaces/GameAccount";
import { UserContext } from "../../Context/UserContext";
import IUserDetails from "../../Interfaces/UserDetails";
import UserService from "../../../services/UserService";
import { useTranslation } from "react-i18next";
import ITournamentMemberStatus from "../../Interfaces/TournamentMemberStatus";
import ITournamentTeamMember from "../../Interfaces/TournamentTeamMember";
import ITournamentTeam from "../../Interfaces/TournamentTeam";
import { string } from "yup";

// Props specific to the CheckinTeamTournamentModal
type Props = {
  loading: boolean;
  tournament: ITournament;
  selectUser?: IUserDetails;
  memberStatus: ITournamentMemberStatus;
  team: ITournamentTeam;
  onConfirm: (
    members: Array<string>,
    data: {
      teamMembers: Array<{
        userId?: string | undefined;
        gameAccount: IGameAccount | undefined;
      }>;
      password?: string | undefined;
    }
  ) => void;
};

interface IDisplayValues {
  id: string | undefined;
  game: string | undefined;
  console: string | undefined;
  username: string | undefined;
  displayName?: string;
}

const CheckinTeamTournamentModal = (props: Props) => {
  const { loading, tournament, selectUser, memberStatus, team } = props;
  let { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");
  const [gameAccounts, setGameAccounts] = useState<Array<IGameAccount>>([]);
  const [gameAccountNeeded, setGameAccountNeeded] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState<{ [key: string]: IUserDetails }>({});
  const [displayValues, setDisplayValues] = useState<{
    [key: string]: Array<IDisplayValues>;
  }>({});
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: Array<IDisplayValues>;
  }>({});
  const [selectedUser, setSelectedUser] = useState(
    selectUser ? selectUser : user
  );
  const [actualUser, setActualUser] = useState<IUserDetails>();
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setGameAccountNeeded(tournament?.gameAccountNeeded);

    const newDisplayValues: { [key: string]: Array<IDisplayValues> } = {};
    const newSelectedValues: { [key: string]: Array<IDisplayValues> } = {};

    team.members.forEach((member) => {
      UserService.getUser(member.userId)
        .then((Ruser) => {
          if (Ruser.gameAccounts) {
            const userAccounts = Ruser.gameAccounts.filter(
              (account) =>
                tournament?.console.includes(account.console || "") &&
                account.game === tournament?.game
            );
            const displayValues: Array<IDisplayValues> = userAccounts.map(
              (account) => ({
                ...account,
                displayName: `USERNAME: ${account.username} - ${t(
                  "players.platform"
                )}: ${account.console}`,
              })
            );
            newDisplayValues[member.userId] = displayValues;
            newSelectedValues[member.userId] =
              displayValues.length > 0 ? [displayValues[0]] : [];
            setDisplayValues((prev) => ({
              ...prev,
              [member.userId]: displayValues,
            }));
            setSelectedValues((prev) => ({
              ...prev,
              [member.userId]:
                displayValues.length > 0 ? [displayValues[0]] : [],
            }));
          }
          setUsers((prev) => ({
            ...prev,
            [member.userId]: Ruser,
          }));
        })
        .catch((error) => {
          // Handle loading state if applicable
        });
    });
  }, [tournament, team]);

  const onSelect = (userId: string, event: any) => {
    const { displayName, ...newData } = event[0];
    setSelectedValues((prev) => ({
      ...prev,
      [userId]: [newData],
    }));
  };

  const onRemove = (userId: string) => {
    setSelectedValues((prev) => ({
      ...prev,
      [userId]: [],
    }));
  };

  const handleAccountAdded = (gameAccount: IGameAccount) => {
    if (actualUser && actualUser.id) {
      const userId = actualUser.id;

      const displayValue: IDisplayValues = {
        ...gameAccount,
        displayName: `USERNAME: ${gameAccount.username} - ${t(
          "players.platform"
        )}: ${gameAccount.console}`,
      };

      setDisplayValues((prev) => ({
        ...prev,
        [userId]: [...(prev[userId] || []), displayValue],
      }));

      setSelectedValues((prev) => ({
        ...prev,
        [userId]: [displayValue],
      }));
    }
  };

  const onConfirm = () => {
    const password =
      tournament?.open === false ? passwordInputRef?.current?.value : undefined;

    const teamMembers = team.members.map((member) => {
      return {
        userId: member.userId,
        gameAccount: selectedValues[member.userId]?.[0],
      };
    });

    let data: {
      partyId: string;
      teamMembers: Array<{
        userId?: string | undefined;
        gameAccount: IGameAccount | undefined;
      }>;
      password?: string | undefined;
    } = {
      partyId: team.partyId,
      teamMembers,
    };

    data.password = password;

    props.onConfirm(
      team.members.map((member) => member.userId),
      data
    );
  };

  const onCloseModal = () => {
    if (selectedUser?.id && selectedUser?.id.length >= 24) {
      UserService.getUser(selectedUser.id, true).then((uResponse) => {
        setSelectedUser(uResponse);
        if (uResponse.id === user?.id) {
          setUser(uResponse);
        }
      });
    }

    setShowModal(false);
  };

  return (
    <>
      <div className="container">
        <div className="space-divider-20"></div>
        {gameAccountNeeded && (
          <div className="columns">
            <div className="column col-2 hide-xs"></div>
            {/* Team Game Accounts */}
            <div className="column col-8 col-xs-12">
              <h6 className="skp-text-light text-uppercase">
                {t("teams.selectTeamGameAccount")}
              </h6>
              {team.members.map((member) => (
                <div key={member.userId} className="skp-form-control">
                  <label className="skp-text-light">
                    {t("teams.selectGameAccountFor")}{" "}
                    {users[member.userId]?.username || t("teams.unknownUser")}
                  </label>
                  <Multiselect
                    options={displayValues[member.userId] || []}
                    selectedValues={
                      selectedValues[member.userId]
                        ? selectedValues[member.userId]
                        : []
                    }
                    displayValue="displayName"
                    onSelect={(e) => onSelect(member.userId, e)}
                    onRemove={() => onRemove(member.userId)}
                    singleSelect={true}
                    closeIcon="cancel"
                    placeholder={t("teams.selectTeamGameAccount")}
                    avoidHighlightFirstOption={true}
                  />
                  <button
                    className="skp-btn skp-btn-secondary"
                    onClick={() => {
                      setActualUser(users[member.userId]);
                      setShowModal(true);
                    }}
                  >
                    {t("teams.addGameAccountForMember")}
                  </button>
                </div>
              ))}
            </div>
            <div className="column col-2 hide-xs"></div>
            <div className="space-divider-20"></div>

            <div className="column col-12 text-right">
              <button
                disabled={Object.values(selectedValues).some(
                  (val) => !val.length
                )}
                className="skp-btn skp-btn-primary"
                onClick={onConfirm}
              >
                {loading ? (
                  <span className="loading"></span>
                ) : (
                  t("tournament.join.checkinTeamTournament")
                )}
              </button>
            </div>
          </div>
        )}
        {!gameAccountNeeded && (
          <div className="columns">
            <div className="column col-2 hide-xs"></div>
            {/* Team Welcome Message */}
            <div className="column col-8 col-xs-12">
              <h6 className="skp-text-light text-uppercase">
                {t("teams.welcomeToTeamTournament")}
              </h6>
            </div>
            <div className="column col-2 hide-xs"></div>
            <div className="space-divider-20"></div>
            <div className="column col-12 text-right">
              <button className="skp-btn skp-btn-primary" onClick={onConfirm}>
                {loading ? (
                  <span className="loading"></span>
                ) : (
                  t("tournament.join.checkinTeamTournament")
                )}
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Add account modal */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={t("teams.addTeamGameAccount")}
        size="large"
      >
        <>
          {actualUser && (
            <AddSingleGameAccountModal
              tournament={tournament}
              selectUser={actualUser}
              closeModal={onCloseModal}
              onAccountAdded={handleAccountAdded}
            ></AddSingleGameAccountModal>
          )}
        </>
      </Modal>
    </>
  );
};

export default CheckinTeamTournamentModal;
