import { useContext, useState } from "react";
import constant from "../../../../constants";
import ETeamPlayerRole from "../../../../enums/TeamPlayerRole";
import { UserContext } from "../../../Context/UserContext";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import EditTournamentTeamModal from "../../../Modals/EditTournamentTeamModal/EditTournamentTeamModal";
import TournamentTeamIssues from "../../../Modals/TournamentTeamIssues/TournamentTeamIssues";
import TeamStatusDropdown from "../../../UiLibrary/TeamStatusDropdown";
import UserProfileWithDetailsModal from "../../../UiLibrary/User/UserProfileWithDetailsModal";
import Modal from "../../../UiLibrary/Modal";
import ITournamentTeam from "../../../Interfaces/TournamentTeam";
import TournamentService from "../../../../services/TournamentService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined;
  userStatus: ITournamentMemberStatus;
  onReloadTournament: (tournament?: ITournament | undefined) => void;
};

interface IRemoveModal {
  show: boolean;
  team?: ITournamentTeam | undefined;
  index?: number;
}

const TeamsTab = (props: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { tournament, userStatus } = props;
  const isTournamentAdmin = tournament?.admins?.includes(user?.id!);
  const isAdmin =
    isTournamentAdmin || (user?.roles && user.roles.includes("TournamentAdmin"))
      ? true
      : false;
  const [showRemoveModal, setShowRemoveModal] = useState<
    IRemoveModal | undefined
  >({
    show: false,
    team: undefined,
    index: -1,
  });
  const today = new Date();
  const registration = new Date(tournament?.end_registration || "");
  const registrationClosed = registration < today;
  const showCheckin = tournament?.playerType === 1;
  const isCheckinPresent =
    tournament?.checkin === undefined || tournament.checkin;

  const [loading, setLoading] = useState(false);

  const [isSolo, setIsSolo] = useState(tournament?.teamSize === 1);

  const removeTournament = () => {
    setLoading(true);
    const data = { isAdmin: true };
    /*if ( tournament?.confirmedMembers?.includes(playerId) ) {
      checkoutTournament()
    }*/
    TournamentService.unSubscribeTournament(
      tournament?.id!,
      showRemoveModal?.team?.leader!,
      data
    )
      .then(() => {
        toast.success("Team removed successfully");
        props.onReloadTournament();
        setShowRemoveModal({ show: false });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="column col-12">
      {/* No Teams */}
      {tournament?.teams.length === 0 && (
        <div className="columns skp-text-light text-center">
          <div className="space-divider"></div>
          <div className="column col-12">
            <h1>
              <i className="las la-user-friends"></i>
            </h1>
          </div>
          <div className="column col-12">
            <h5>No teams</h5>
          </div>
          <div className="column col-12">There are no teams available yet.</div>
        </div>
      )}
      {/* END No Teams */}

      {tournament && tournament.status <= 1 && (
        <TournamentTeamIssues userStatus={userStatus} tournament={tournament} />
      )}

      {tournament &&
        tournament.teams.length > 0 &&
        tournament?.teams
          .filter((team) => team.members !== undefined)
          .map((team, index) => (
            <div key={`team-${index}`} className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-8 skp-text-primary">
                {!team.partyName && <h5>Team {index + 1}</h5>}
                {team.partyName && (
                  <h5>
                    <div className="skp-text-label skp-text-light text-uppercase">
                      Team {index + 1}{" "}
                    </div>{" "}
                    {team.partyName}{" "}
                    {tournament?.confirmedMembers?.includes(team.partyId) && (
                      <span className="skp-chip skp-text-xxsmall skp-text-ternary">
                        {t("common.confirmed")}
                      </span>
                    )}
                    {isCheckinPresent &&
                      showCheckin &&
                      registrationClosed &&
                      !tournament?.confirmedMembers?.includes(team.partyId) && (
                        <span className="skp-chip skp-text-xxsmall skp-btn-alert-border">
                          {t("common.noCheckIn")}
                        </span>
                      )}
                  </h5>
                )}
              </div>
              {isAdmin && tournament.status === 0 && (
                <div
                  className="column col-2 flex-centered"
                  style={{ justifyContent: "right" }}
                >
                  <button
                    className="skp-btn skp-btn-primary skp-text-small"
                    onClick={() =>
                      setShowRemoveModal({
                        show: true,
                        team: team,
                        index: index,
                      })
                    }
                  >
                    Remove Team
                  </button>
                </div>
              )}
              {Number(tournament.status) === 0 &&
                (team.leader === user?.id || isAdmin) &&
                !isSolo && (
                  <div
                    className="column col-2 flex-centered"
                    style={{ justifyContent: "right" }}
                  >
                    <EditTournamentTeamModal
                      team={team}
                      tournament={tournament}
                      onReloadTournament={props.onReloadTournament}
                    />
                  </div>
                )}

              <div className="column col-12">
                <dl className="bordered-list">
                  {team.members &&
                    team.members.map((member, index) => (
                      <dd className="columns" key={member + index.toString()}>
                        <div className="column col-8">
                          <UserProfileWithDetailsModal
                            userId={member.userId}
                            tournament={tournament}
                          >
                            <span
                              className={`skp-chip status-${
                                ETeamPlayerRole[member.role]
                              } skp-text-small`}
                            >
                              {ETeamPlayerRole[member.role]}
                            </span>

                            {member.userId === team.leader && !isSolo && (
                              <span className="skp-chip status-captain skp-text-small">
                                Leader
                              </span>
                            )}
                          </UserProfileWithDetailsModal>
                        </div>
                        <div
                          className="column col-4 flex-centered"
                          style={{ justifyContent: "right" }}
                        >
                          {member.userId !== team?.leader &&
                            !(
                              userStatus?.team?.leader === team?.leader &&
                              member.userId !== team?.leader &&
                              userStatus?.team?.leader === user?.id
                            ) && (
                              <span
                                className={`skp-chip status-${
                                  constant.INVITE_STATUS[member.status]
                                } skp-text-small`}
                              >
                                {constant.INVITE_STATUS[member.status]}
                              </span>
                            )}

                          {Number(tournament.status) === 0 &&
                            userStatus?.team?.leader === team?.leader &&
                            member.userId !== team?.leader &&
                            userStatus?.team?.leader === user?.id && (
                              <>
                                <TeamStatusDropdown
                                  member={member}
                                  team={team}
                                  tournament={tournament}
                                  onReloadTournament={props.onReloadTournament}
                                />
                              </>
                            )}
                        </div>
                      </dd>
                    ))}
                </dl>
              </div>
            </div>
          ))}
      <Modal
        show={showRemoveModal?.show}
        onClose={() => setShowRemoveModal({ show: false })}
        onConfirm={removeTournament}
        loading={loading}
        confirmation="Remove Tournament"
        size="small"
        title="Remove Tournament"
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider"></div>
            <div className="column col-12 text-center skp-text-primary">
              <span>
                Are you sure want to remove team from tournament{" "}
                <strong>{tournament?.name}</strong> ?
              </span>
            </div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TeamsTab;
