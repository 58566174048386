enum EGames {
  FORTNITE = "FORTNITE",
  APEX_LEGENDS = "APEX_LEGENDS",
  FIFA22 = "FIFA22",
  EAFC24 = "EAFC24",
  EAFC25 = "EAFC25",
  CLASH_ROYALE = "CLASH_ROYALE",
  CLASH_ROYALE_WITH_BRACKET = "CLASH_ROYALE_WITH_BRACKET",
  LEAGUE_OF_LEGENDS = "LEAGUE_OF_LEGENDS",
}

export default EGames;
