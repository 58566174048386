import { useState, useEffect, useContext } from "react";
import "./tournaments-tab.scss";
import KeyCloackService from "../../../../services/KeyCloackService";
import { Link } from "react-router-dom";
import TournamentService from "../../../../services/TournamentService";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import UserProfileWithDetailsModal from "../../../UiLibrary/User/UserProfileWithDetailsModal";
import ITournament from "../../../Interfaces/Tournament";
import { UserContext } from "../../../Context/UserContext";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AddPlayerButton from "../../../UiLibrary/AddPlayerButton/AddPlayerButton";
import TournamentUserStateButton from "../TournamentSinglePages/TournamentUserStateButton";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined;
  onLogin: () => void;
  onReloadTournament: (tournament?: ITournament) => void;
};

const PlayerTab = ({ tournament, ...props }: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [paginatedItems, setPaginatedItems] = useState<Array<string>>([]);

  const tournamentAdmin = tournament?.admins?.includes(user?.id!);
  const isAdmin =
    tournament?.owner === user?.id ||
    tournamentAdmin ||
    (user?.roles && user.roles.includes("TournamentAdmin"))
      ? true
      : false;

  const isOwner = tournament?.owner === user?.id;

  const today = new Date();
  const registration = new Date(tournament?.end_registration || "");
  const registrationClosed = registration < today;
  const checkin = new Date(tournament?.end_checkin || "");
  const checkinClosed = !isNaN(+checkin) ? checkin < today : true;
  const showCheckin = tournament?.playerType === 0;
  const isCheckinPresent =
    tournament?.checkin === undefined || tournament.checkin;

  useEffect(() => {
    let items: Array<string> = [];

    const index =
      tournament &&
      tournament?.members.filter((x) => x.length === 24).length >= 10
        ? 10
        : tournament?.members.filter((x) => x.length === 24).length;
    if (index) {
      for (let i = 0; i < index; i++) {
        if (tournament?.members.filter((x) => x.length === 24)[i]) {
          items.push(tournament?.members.filter((x) => x.length === 24)[i]);
        }
      }
    }

    setPaginatedItems(items);
  }, [tournament]);

  const addAdmin = (playerId: string) => {
    if (!tournament) {
      return;
    }

    setLoading(true);
    TournamentService.addAdmin(tournament.id, [playerId])
      .then((tournament) => {
        props.onReloadTournament(tournament);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const removeAdmin = (playerId: string) => {
    if (!tournament) {
      return;
    }
    setLoading(true);
    TournamentService.removeAdmin(tournament.id, [playerId])
      .then((Rtournament) => {
        props.onReloadTournament(Rtournament);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const isTournamentAdmin = (playerId: string) => {
    return tournament?.admins?.includes(playerId);
  };

  const fetchMoreData = () => {
    if (!tournament?.members) {
      console.log("No members found in the tournament.");
      return;
    }

    const validMembers = tournament.members.filter((x) => x.length === 24);

    const nextItems = validMembers.slice(
      paginatedItems.length,
      paginatedItems.length + 10
    );

    if (nextItems.length > 0) {
      setPaginatedItems((prevItems) => [...prevItems, ...nextItems]);
    } else {
      console.log("No more items to add.");
    }
  };

  const hasMore = tournament
    ? tournament.members.filter((x) => x.length === 24).length >
      paginatedItems.length
    : false;

  return (
    <>
      <div className="players-tab container tab-with-table">
        {(isAdmin || isOwner) &&
          tournament?.playerType === 0 &&
          tournament?.status === 0 && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <AddPlayerButton
                  tournament={tournament}
                  onReloadTournament={props.onReloadTournament}
                />
              </div>
            </div>
          )}

        {tournament && tournament.members?.length > 0 && (
          <div className="columns">
            <div className="column col-12">
              <div className="space-divider-20"></div>
              <h5 className="skp-text-primary">{t("sidePanel.registered")}</h5>

              <dl>
                <InfiniteScroll
                  dataLength={paginatedItems.length}
                  scrollableTarget="scrollableDiv"
                  next={fetchMoreData}
                  hasMore={
                    tournament?.members.filter((x) => x.length === 24).length >
                    paginatedItems.length
                  }
                  style={{ overflow: "hidden" }}
                  loader={
                    <dd>
                      <div className="loading"></div>
                    </dd>
                  }
                  scrollThreshold={0.1}
                  endMessage={
                    <>
                      {paginatedItems.length >= 10 && (
                        <>
                          <p
                            className="text-center skp-text-light m-2"
                            style={{ textAlign: "center" }}
                          >
                            {t("common.end")}
                          </p>
                        </>
                      )}
                    </>
                  }
                >
                  <TransitionGroup className="todo-list">
                    {tournament?.members &&
                      paginatedItems.map((playerId) => (
                        <CSSTransition
                          key={`players-${playerId}`}
                          timeout={300}
                          classNames="item"
                        >
                          <dd key={playerId} className="columns flex-centered">
                            <div className="column col-8 col-xs-12">
                              <Link to={`/users/${playerId}/overview`}>
                                <UserProfileWithDetailsModal
                                  userId={playerId}
                                  tournament={tournament}
                                >
                                  {tournament?.admins?.includes(playerId) && (
                                    <span className="skp-chip skp-text-xxsmall status-captain">
                                      {t("common.admin")}
                                    </span>
                                  )}
                                  {tournament?.owner === playerId && (
                                    <span className="skp-chip skp-text-xxsmall status-captain">
                                      {t("common.owner")}
                                    </span>
                                  )}
                                  {tournament?.confirmedMembers?.includes(
                                    playerId
                                  ) && (
                                    <span className="skp-chip skp-text-xxsmall skp-text-ternary">
                                      {t("common.confirmed")}
                                    </span>
                                  )}
                                  {isCheckinPresent &&
                                    showCheckin &&
                                    registrationClosed &&
                                    !tournament?.confirmedMembers?.includes(
                                      playerId
                                    ) && (
                                      <span className="skp-chip skp-text-xxsmall skp-btn-alert-border">
                                        {t("common.noCheckIn")}
                                      </span>
                                    )}
                                </UserProfileWithDetailsModal>
                              </Link>
                            </div>
                            <div className="column col-4 col-xs-12 text-right">
                              {user?.id &&
                                (tournament?.owner === user?.id ||
                                  tournament?.admins?.includes(user?.id)) && (
                                  <>
                                    {/*
                                  Se il torneo non è ancora nelle fasi di ongoing o completed devo poter cambiare lo stato
                                  del player, in particolare:
                                  - se il player si è iscritto, devo poterlo rimuovere
                                  - se sono nella fase di check-in o successiva e il player si è iscritto devo potergli 
                                      forzare il check-in
                                  - se sono nella fase di check-in o successiva e il player ha fatto il check-in devo
                                      potergli forzare il check-out
                                */}
                                    {tournament && (
                                      <TournamentUserStateButton
                                        tournament={tournament}
                                        playerId={playerId}
                                        removeState={true}
                                        registrationClosed={registrationClosed}
                                        checkinClosed={checkinClosed}
                                        onReloadTournament={
                                          props.onReloadTournament
                                        }
                                      />
                                    )}

                                    {/* 
                                  Controllo se sono nella fase di checkin e metto il pulsante per far fare il checkin al
                                  player da parte dell'admin
                                */}
                                    {isCheckinPresent &&
                                      showCheckin &&
                                      tournament && (
                                        <TournamentUserStateButton
                                          tournament={tournament}
                                          playerId={playerId}
                                          removeState={false}
                                          registrationClosed={
                                            registrationClosed
                                          }
                                          checkinClosed={checkinClosed}
                                          onReloadTournament={
                                            props.onReloadTournament
                                          }
                                        />
                                      )}
                                  </>
                                )}
                              {tournament?.winner === playerId && (
                                <div>
                                  <i
                                    className="las la-trophy"
                                    style={{
                                      fontSize: "20px",
                                      color: "#DAAF37",
                                    }}
                                  ></i>{" "}
                                  <span className="skp-text-primary">1st</span>
                                </div>
                              )}
                              {playerId !== tournament?.owner &&
                                tournament?.owner === user?.id && (
                                  <span className="admin-btn-wrapper">
                                    {!isTournamentAdmin(playerId) && (
                                      <button
                                        disabled={loading}
                                        className="skp-btn skp-btn-primary skp-text-small"
                                        onClick={() => addAdmin(playerId)}
                                      >
                                        {t("players.addAdmin")}
                                      </button>
                                    )}
                                    {isTournamentAdmin(playerId) && (
                                      <button
                                        disabled={loading}
                                        className="skp-btn skp-btn-alert skp-text-small"
                                        onClick={() => removeAdmin(playerId)}
                                      >
                                        {t("players.removeAdmin")}
                                      </button>
                                    )}
                                  </span>
                                )}
                            </div>
                          </dd>
                        </CSSTransition>
                      ))}
                  </TransitionGroup>
                </InfiniteScroll>
              </dl>
            </div>
            <div className="space-divider-40"></div>
            {/* Login button */}
            {!KeyCloackService.isLoggedIn() && (
              <div className="column col-12 text-center">
                <div className="skp-text-light">{t("players.mustLogin")}</div>
                <div className="space-divider-20"></div>
                <button
                  className="skp-btn skp-btn-primary"
                  onClick={props.onLogin}
                >
                  {t("common.login")}
                </button>
              </div>
            )}
          </div>
        )}
        {tournament?.members?.length === 0 && (
          <div className="columns skp-text-light text-center">
            <div className="space-divider"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-user-friends"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>{t("players.noPlayers")}</h5>
            </div>
            <div className="column col-12">
              {t("players.noPlayerRegistered")}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlayerTab;
