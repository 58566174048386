import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import TournamentImageBanner from "../../UiLibrary/TournamentImageBanner";
import ReactTimeAgo from "react-time-ago";
import "./tournament.scss";
import ProfileName from "../../UiLibrary/ProfileName";
import Moment from "moment";
import Countdown from "react-countdown";
import KeyCloackService from "../../../services/KeyCloackService";
import { Scrollbars } from "react-custom-scrollbars";
import TournamentPageControl from "./TournamentPageControl";
import TournamentSideControl from "./TournamentSideControl";
import constant from "../../../constants";
import AddToFavourite from "../../UiLibrary/AddToFavourite";
import { isMobile } from "react-device-detect";
import Marquee from "react-text-marquee";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import ITournamentMemberStatus from "../../Interfaces/TournamentMemberStatus";
import TTournament from "../../../types/TTournament";
import TournamentImageBannerUpload from "../../UiLibrary/TournamentImageBannerUpload";
import TournamentJoinButtons from "./TournamentSinglePages/TournamentJoinButtons";
import { UserContext } from "../../Context/UserContext";
import Modal from "../../UiLibrary/Modal";
import TournamentService from "../../../services/TournamentService";
import GAMES from "../../../constants-games";
import { useTranslation } from "react-i18next";

const TournamentPage = (props: TTournament) => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { tournament, loadingTournament, urlParams, isSettingsPage } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tournamentDuration, setTournamentDuration] = useState<string>();

  const isTournamentAdmin = tournament?.admins?.includes(user?.id!);
  const isAdmin =
    tournament?.owner === user?.id ||
    isTournamentAdmin ||
    (user?.roles && user.roles.includes("TournamentAdmin"))
      ? true
      : false;

  const [showSideBar, setShowSideBar] = useState(false);
  const [memberStatus, setMemberStatus] = useState<ITournamentMemberStatus>({
    isPending: false,
    team: undefined,
    isLeader: false,
    accepted: false,
    joined: false,
    isRejected: false,
    isAdmin: false,
    isOwner: false,
    checkedIn: false,
  });

  let status = tournament?.status;
  let upcomingTournament = status === 0;
  let onGoingTournament = status === 1;
  let completedTournament = status === 2;

  const today = new Date();
  const startRegistration = new Date(tournament?.start_registration || "");
  const registrationStarted = startRegistration < today || !upcomingTournament;
  const registration = new Date(tournament?.end_registration || "");
  const registrationClosed = registration < today || !upcomingTournament;
  const checkin = new Date(tournament?.end_checkin || "");
  const checkinClosed = checkin < today || !upcomingTournament;

  const isCheckinPresent =
    tournament?.checkin === undefined || tournament.checkin;

  const getTournamentDuration = () => {
    if (!tournament?.start_tournament || !tournament?.end_tournament) {
      return;
    }
    const startT = new Date(tournament?.start_tournament);
    const endT = new Date(tournament?.end_tournament);

    const result = endT.getTime() - startT.getTime();
    const diffDays = Math.floor(result / 86400000); // days
    const diffHrs = Math.floor((result % 86400000) / 3600000); // hours
    const diffMins = Math.round(((result % 86400000) % 3600000) / 60000); // minutes

    let duration;
    if (diffDays === 0) {
      duration = diffHrs + " hours, " + diffMins + " minutes";
      if (diffHrs === 0) {
        duration = diffMins + " minutes";
        if (diffMins === 60) {
          duration = diffHrs + 1 + " hour";
        }
      } else {
        if (diffMins === 60) {
          duration = diffHrs + 1 + " hours";
        } else if (diffMins === 0) {
          if (diffHrs === 1) {
            duration = "1 hour";
          } else {
            duration = diffHrs + " hours";
          }
        }
      }
    } else {
      duration =
        diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes";
    }
    // if(duration === '60 minutes') {
    //   duration = '1 hour'
    // }
    setTournamentDuration(duration);
  };

  useEffect(() => {
    let isRejected = false;
    let isLeader = false;
    let myTeam;
    let isPending = false;
    let accepted = false;
    getTournamentDuration();
    tournament?.teams.forEach((team: any) => {
      if (team.members) {
        const ismember = team.members.filter(
          (member: any) => member.userId === user?.id
        );

        if (team.leader === user?.id) {
          isLeader = true;
        }

        if (ismember.length > 0) {
          myTeam = team;
        }

        if (ismember.length > 0 && Number(ismember[0].status) === 0) {
          isPending = true;
        }

        if (ismember.length > 0 && Number(ismember[0].status) === 2) {
          isRejected = true;
        }

        if (
          ismember.length > 0 &&
          Number(ismember[0].status) === 1 &&
          !isLeader
        ) {
          accepted = true;
        }
      }
    });
    setMemberStatus({
      isRejected: isRejected,
      joined: tournament?.members?.includes(user?.id!) ? true : false,
      isPending: isPending,
      team: myTeam,
      isLeader: isLeader,
      accepted: accepted,
      isAdmin: isAdmin ? isAdmin : false,
      isOwner: tournament?.owner === user?.id,
      checkedIn:
        tournament?.playerType === 0
          ? tournament?.confirmedMembers?.includes(user?.id!) || false
          : tournament?.confirmedMembers?.includes(myTeam?.partyId!) || false,
    });
  }, [tournament, user, isAdmin]);

  const toggleSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  const [showStatusModal, setShowStatusModal] = useState<{
    show: boolean;
    title?: string;
    status?: number;
  }>({
    show: false,
    title: undefined,
    status: undefined,
  });
  const updateStatus = (status: number) => {
    let title;
    switch (status) {
      case 1:
        title = t("status.start");
        break;
      case 2:
        title = t("status.complete");
        break;

      default:
        break;
    }
    setShowStatusModal({ show: true, title: title, status: status });
  };

  const updateTournamentStatus = (status: number | undefined) => {
    if (!status) {
      return;
    }
    setShowStatusModal({ show: false });
    let data = {
      status: status,
    };
    props.updateTournament(data);
  };

  const deleteTournament = () => {
    if (!tournament) {
      return;
    }
    TournamentService.deleteTournament(tournament.id)
      .then(() => {
        setShowDeleteModal(false);
        navigate("/home");
      })
      .catch((error) => {
        setShowDeleteModal(false);
      });
  };

  return (
    <>
      <div className="skp-page-with-sidebar skp-page-with-image-banner">
        {/* PAGE CONTENT */}
        <Scrollbars
          renderView={(props: any) => <div {...props} id="scrollableDiv" />}
        >
          <div className="skp-page-with-sidebar-main-content">
            {/* IMAGE BANNER */}
            {user?.id === tournament?.owner && (
              <TournamentImageBannerUpload
                tournament={tournament}
                updateTournament={props.loadTournament}
              />
            )}
            {tournament && <TournamentImageBanner tournament={tournament} />}
            {/* PAGE TOP */}
            <div className="container page-top">
              <div className="columns">
                <div className="column col-8 col-xs-12 text-center-xs">
                  <span className="skp-text-label skp-text-light">
                    {t("common.tournament")}
                  </span>{" "}
                  <br></br>
                  <h1 className="skp-text-primary skp-heading-no-margin">
                    <Marquee text={tournament?.name || ""} />
                  </h1>
                  {tournament && tournament.start_tournament && (
                    <div className="skp-text-light">
                      <span className="skp-text-primary">
                        <ReactTimeAgo
                          date={new Date(tournament?.start_tournament)}
                        />{" "}
                        -{" "}
                        {Moment(tournament?.start_tournament).format(
                          "MMM DD, hh:mm A"
                        )}{" "}
                        <AddToFavourite tournament={tournament} />
                      </span>
                    </div>
                  )}
                  {/* SEZIONE SPECIFICA PER CLASH_ROYALE */}
                  {tournamentDuration &&
                    tournament?.game === GAMES.CLASH_ROYALE.ENUM && (
                      <div className="skp-text-light skp-text-small pt-2 pb-2">
                        Duration:{" "}
                        <span className="skp-text-primary skp-text-default">
                          {tournamentDuration}
                        </span>
                      </div>
                    )}
                  {isAdmin && (
                    <>
                      {!isSettingsPage && (
                        <Link
                          to={`/tournaments/${tournament?.id}/settings/general`}
                          className="skp-link skp-text-small"
                        >
                          {t("common.settings")} <i className="las la-cog"></i>
                        </Link>
                      )}
                      {isSettingsPage && (
                        <Link
                          to={`/tournaments/${tournament?.id}/overview`}
                          className="skp-link skp-text-small"
                        >
                          <i className="las la-arrow-left"></i>{" "}
                          {t("tournament.viewTournament")}
                        </Link>
                      )}
                    </>
                  )}
                  <div className="space-divider-5"></div>
                  {KeyCloackService.isLoggedIn() && (
                    <div className="skp-text-light">
                      {t("tournament.hostedBy")}{" "}
                      <Link to={`/users/${tournament?.owner}/overview`}>
                        <ProfileName owner={tournament?.owner} />{" "}
                      </Link>
                      {tournament?.yuniteId && (
                        <span className="skp-chip yunite skp-text-default text-uppercase">
                          {" "}
                          {t("tournament.yunite")}
                        </span>
                      )}
                      {!tournament?.yuniteId && !tournament?.open && (
                        <span className="skp-chip yunite skp-text-default text-uppercase">
                          {" "}
                          {t("tournament.private")}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <div className="column col-12 text-center show-xs m-2">
                  <button
                    className="skp-btn skp-btn-primary skp-text-small"
                    onClick={toggleSideBar}
                  >
                    <i className="las la-bars"></i>{" "}
                    {t("tournament.tournamentStatus")}
                  </button>
                </div>
                <div
                  className={`column col-4 col-xs-12 text-right text-center-xs`}
                >
                  {completedTournament &&
                    (tournament?.winner || tournament?.yuniteId) && (
                      <span className="skp-chip skp-text-default text-uppercase status-completed">
                        {t("status.completed")}
                      </span>
                    )}

                  {upcomingTournament && (
                    <div className="skp-text-primary skp-text-default">
                      {!registrationStarted
                        ? t("tournament.registrationStartsIn")
                        : !registrationClosed
                        ? t("tournament.registrationClosesIn")
                        : !checkinClosed &&
                          tournament?.end_checkin &&
                          isCheckinPresent
                        ? t("tournament.checkInClosesIn")
                        : t("tournament.startsIn")}
                      <Countdown
                        date={
                          !registrationStarted
                            ? startRegistration
                            : !registrationClosed
                            ? registration
                            : !checkinClosed &&
                              tournament?.end_checkin &&
                              isCheckinPresent
                            ? checkin
                            : new Date(tournament?.start_tournament || "")
                        }
                      />
                    </div>
                  )}

                  {onGoingTournament && (
                    <span className="skp-chip  skp-text-default text-uppercase status-ongoing">
                      {t("status.ongoing")}
                    </span>
                  )}

                  {!onGoingTournament &&
                    !completedTournament &&
                    !registrationStarted && (
                      <div
                        className={`skp-chip skp-text-default text-uppercase ${"status-awaiting"}`}
                      >
                        {t("tournament.registrationComingSoon")}
                      </div>
                    )}

                  {!onGoingTournament &&
                    !completedTournament &&
                    registrationClosed && (
                      <div
                        className={`skp-chip skp-text-default text-uppercase ${
                          registrationClosed ? "status-closed" : "status-1"
                        }`}
                      >
                        {registrationClosed
                          ? t("tournament.registrationClosed")
                          : t("tournament.registrationOpen")}
                      </div>
                    )}

                  {!onGoingTournament &&
                    !completedTournament &&
                    checkinClosed &&
                    !tournament?.yuniteId &&
                    isCheckinPresent && (
                      <div
                        className={`skp-chip skp-text-default text-uppercase ${
                          checkinClosed ? "status-closed" : "status-1"
                        }`}
                      >
                        {checkinClosed
                          ? t("tournament.checkInClosed")
                          : t("tournament.checkInOpen")}
                      </div>
                    )}

                  {/* Join Buttons */}
                  {!isSettingsPage && tournament && (
                    <TournamentJoinButtons
                      tournament={tournament}
                      memberStatus={memberStatus}
                      registrationStarted={registrationStarted}
                      registrationClosed={registrationClosed}
                      checkinClosed={checkinClosed}
                      onReloadTournament={props.loadTournament}
                      showCheckin={isCheckinPresent}
                    />
                  )}

                  <div className="skp-text-label m-2 skp-text-light">
                    {t("tournament.shareOn")}
                  </div>
                  <TwitterShareButton
                    title={`Join ${tournament?.name} - ${tournament?.game} @`}
                    url={`${constant.APP_URL}/tournaments/${tournament?.id}`}
                    hashtags={[tournament?.game || "", "Skillpro"]}
                  >
                    <TwitterIcon round={true} size={35} />
                  </TwitterShareButton>

                  <FacebookShareButton
                    url={`${constant.APP_URL}/tournaments/${tournament?.id}`}
                    quote={`Join ${tournament?.name} - ${tournament?.game} @`}
                    className="Demo__some-network__share-button"
                    style={{ marginLeft: 10 }}
                  >
                    <FacebookIcon size={35} round />
                  </FacebookShareButton>

                  {isAdmin && (
                    <>
                      {!isSettingsPage && (
                        <>
                          <div className="space-divider-20"></div>
                          <div className="column">
                            <Link
                              to={`/tournaments/${tournament?.id}/settings/general`}
                              className="skp-link skp-text-small"
                            >
                              <button className="skp-btn skp-btn-secondary">
                                {t("common.settings")}{" "}
                                <i className="las la-cog"></i>
                              </button>
                            </Link>
                            <Link
                              to={`/tournament-admin/${tournament?.id}`}
                              className="skp-link skp-text-small"
                            >
                              <button className="skp-btn skp-btn-secondary">
                                {t("tournament.adminMatch")}{" "}
                                <i className="las la-cog"></i>
                              </button>
                            </Link>
                          </div>
                        </>
                      )}
                      {isSettingsPage && (
                        <Link
                          to={`/tournaments/${tournament?.id}/overview`}
                          className="skp-link skp-text-small"
                        >
                          <div className="space-divider-20"></div>
                          <button className="skp-btn skp-btn-secondary">
                            {t("tournament.viewTournament")}
                          </button>
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="space-divider-20"></div>

              {!KeyCloackService.isLoggedIn() && (
                <>
                  <div className="skp-text-light column col-12 text-center text-uppercase">
                    <span className="skp-text-primary">
                      {t("messages.tournamentNotLogin")}
                    </span>
                  </div>
                  <div className="space-divider-20"></div>
                </>
              )}
            </div>
            {/* END PAGE TOP */}

            {/* PAGE CONTENT CONTROL */}
            {tournament && (
              <TournamentPageControl
                tournament={tournament}
                urlParams={urlParams}
                userStatus={memberStatus}
                loadTournament={props.loadTournament}
                updateTournament={props.updateTournament}
                isSettingsPage={isSettingsPage}
              />
            )}
            {/* END PAGE CONTENT CONTROL */}
          </div>
        </Scrollbars>
        {/* END PAGE CONTENT */}

        {/* SIDEBAR */}
        <div
          className={`skp-page-with-sidebar-side ${
            isMobile && !showSideBar && "skp-page-with-sidebar-side-hidden"
          }`}
        >
          <div
            className="skp-sidebar-close-icon show-xs skp-text-light"
            onClick={toggleSideBar}
          >
            <i className="las la-times"></i>
          </div>
          {tournament && (
            <TournamentSideControl
              tournament={tournament}
              isSettings={isSettingsPage}
              memberStatus={memberStatus}
              onReloadTournament={props.loadTournament}
              updateStatus={updateStatus}
              deleteTournament={() => setShowDeleteModal(true)}
            />
          )}
        </div>
        {/* END SIDEBAR */}
      </div>

      <Modal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={deleteTournament}
        size="medium"
        title={t("titles.deleteTournament")}
        confirmation={t("common.delete")}
      >
        <div className="container">
          <div className="space-divider-20"></div>
          <div className="columns skp-text-primary">
            <div className="column col-12 text-center">
              {t("messages.checkDeleteTournament")}
              <span className="skp-text-accent">{tournament?.name}</span> ?
            </div>
          </div>
          <div className="space-divider"></div>
        </div>
      </Modal>

      <Modal
        show={showStatusModal.show}
        onClose={() => setShowStatusModal({ show: false })}
        onConfirm={() => updateTournamentStatus(showStatusModal.status)}
        size="medium"
        title={showStatusModal.title}
        confirmation={showStatusModal.title}
      >
        <div className="container">
          <div className="space-divider-20"></div>
          <div className="columns skp-text-primary text-center">
            {showStatusModal.status === 1 && (
              <div className="column col-12">
                {t("messages.tournamentOngoing")}
              </div>
            )}
            {showStatusModal.status === 2 && (
              <div className="column col-12">
                {t("messages.tournamentCompleted")}
              </div>
            )}
          </div>
          <div className="space-divider"></div>
        </div>
      </Modal>
    </>
  );
};

export default TournamentPage;
