import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import PlayerTab from "../TournamentTabs/PlayersTab";
import ResultsTab from "../TournamentTabs/ResultsTab";
import MatchesTab from "../TournamentTabs/MatchesTab";
import BracketsTab from "../TournamentTabs/BracketsTab";
import OverviewTab from "../TournamentTabs/OverviewTab";
import Modal from "../../../UiLibrary/Modal";
import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import TeamsTab from "../TournamentTabs/TeamsTab";
import MatchService from "../../../../services/MatchService";
import { TournamentMatchesContext } from "../../../Context/TournamentMatchesContext";
import MatchDetailsModal from "../../../Modals/MatchDetailsModal/MatchDetailsModal";
import MultiMatchDetailsModal from "../../../Modals/MultiMatchDetailsModal/MultiMatchDetailsModal";
import AnnouncementsTab from "../TournamentSettingsTabs/AnnouncementsTab";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import IMatchTournamentSeed from "../../../Interfaces/MatchTournamentSeed";
import IMatch from "../../../Interfaces/Match";
import { UserContext } from "../../../Context/UserContext";
import GroupsTab from "../TournamentTabs/GroupsTab";
import TournamentService from "../../../../services/TournamentService";
import { EventsContext } from "../../../Context/EventsContext";
import ChatService from "../../../../services/ChatService";
import { NotificationContext } from "../../../Context/NotificationContext";
import { useTranslation } from "react-i18next";
import ChatTab from "../../Chats/ChatTab/ChatTab";
import { debounce } from "lodash";

type Props = {
  tournament: ITournament | undefined;
  userStatus: ITournamentMemberStatus;
  isSettingsPage: boolean;
  urlParams: any;
  loadTournament: (tournament: ITournament | undefined) => void;
};

type ITournamentMatches = {
  matches: Array<IMatch>;
  tournament: ITournament;
};

const TournamentWithBrackets = (props: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    tournamentNotificationId,
    setTournamentNotificationId,
    tournamentNotification,
    setTournamentNotification,
  } = useContext(NotificationContext);

  const tabs = [
    { name: t("tabs.overview"), url: "overview" },
    { name: t("tabs.rules"), url: "rules" },
    { name: t("tabs.groups"), url: "groups" },
    { name: t("tabs.brackets"), url: "brackets" },
    { name: t("tabs.matches"), url: "matches" },
    { name: t("tabs.players"), url: "players" },
    { name: t("tabs.teams"), url: "teams" },
    { name: t("tabs.results"), url: "results" },
    { name: t("tabs.announcements"), url: "announcements" },
    { name: t("tabs.chat"), url: "chat" },
  ];

  const { tournament, urlParams, userStatus } = props;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [matches, setMatches] = useState(tournament?.matches);
  const [loading, setLoading] = useState(false);
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [showMyMatches, setShowMyMatches] = useState(false);
  const [filterNumber, setFilterNumber] = useState();
  const [announcs, setAnnouncs] = useState(-1);
  const [chatToRead, setChatToRead] = useState(-1);
  const [tournamentMatches, setTournamentMatches] =
    useState<ITournamentMatches>();
  const [showMatchModal, setShowMatchModal] = useState<{
    show: boolean;
    match: IMatchTournamentSeed | undefined;
  }>({
    show: false,
    match: undefined,
  });

  const handleSocketNotification = debounce(
    (user, tournament, updateNotificationAndChat) => {
      if (user && user.id) {
        updateNotificationAndChat();
      }
    },
    300 // Debounce di 300 ms
  );

  const providerTournamentMatches = useMemo(
    () => ({ tournamentMatches, setTournamentMatches }),
    [tournamentMatches, setTournamentMatches]
  );

  const updateNotification = (newNotification) => {
    MatchService.setNotification(newNotification).then((notification) => {
      updateNotificationAndChat();
      setTournamentNotification(newNotification);
    });
  };

  const updateNotificationAndChat = () => {
    if (
      !tournamentNotificationId ||
      tournamentNotificationId !== tournament?.id ||
      !tournamentNotification
    ) {
      MatchService.getNotification(tournament!.id, user!.id!).then(
        (notification) => {
          setTournamentNotificationId(tournament!.id);
          setTournamentNotification(notification.data.data);
        }
      );
    } else {
      setAnnouncs(
        tournament?.announcements?.length -
          (tournamentNotification.announcements
            ? tournamentNotification.announcements
            : 0)
      );
      ChatService.countMessageByTournament(tournament!.id)
        .then((messages) => {
          if (urlParams.tab === "chat") {
            const newNotification = tournamentNotification;
            newNotification!.chatRead = messages;
            MatchService.setNotification(newNotification).then(
              (notification) => {
                setTournamentNotification(newNotification);
              }
            );
            setChatToRead(0);
          } else {
            if (tournamentNotification.chatRead) {
              setChatToRead(messages - tournamentNotification.chatRead);
            } else {
              setChatToRead(messages);
            }
          }
        })
        .catch((error) => {
          setChatToRead(0);
        });
    }
  };

  const { socket, isConnected } = useContext(EventsContext);

  useEffect(() => {
    if (tournament && tournament.id && user && user.id) {
      if (
        !tournamentNotificationId ||
        tournamentNotificationId !== tournament.id ||
        !tournamentNotification
      ) {
        MatchService.getNotification(tournament!.id, user!.id!).then(
          (notification) => {
            setTournamentNotificationId(tournament!.id);
            setTournamentNotification(notification.data.data);
          }
        );
      }

      updateNotificationAndChat();

      if (isConnected && socket) {
        socket.on("n-" + tournament.id, () =>
          handleSocketNotification(user, tournament, updateNotificationAndChat)
        );
        socket.on("chat-" + tournament.id, () =>
          handleSocketNotification(user, tournament, updateNotificationAndChat)
        );

        return () => {
          socket.off("n-" + tournament.id);
          socket.off("chat-" + tournament.id);
        };
      }
    }
  }, [tournament, user, isConnected, socket]);

  const clickLink = (url) => {
    if (url === "announcements") {
      if (tournamentNotification) {
        const newNotification = tournamentNotification;
        newNotification!.announcements = tournament?.announcements?.length;
        updateNotification(newNotification);
      }
    } else if (url === "chat") {
      if (tournamentNotification) {
        const newNotification = tournamentNotification;
        ChatService.countMessageByTournament(tournament!.id)
          .then((messages) => {
            newNotification!.chatRead = messages;
            updateNotification(newNotification);
          })
          .catch((error) => {
            console.log("errore");
          });
      }
    }
  };

  useEffect(() => {
    if (!tournament) {
      return;
    }
    if (tournament?.matches.length > 0 && tournament.groups?.length === 0) {
      setLoadingMatches(true);
      MatchService.getTournamentMatches(
        tournament?.id,
        (tournament?.maxPartecipants + 1) * 2 * 9,
        true
      ).then((matches) => {
        setTournamentMatches({ matches: matches, tournament: tournament });
        setLoadingMatches(false);
      });
    }
  }, [tournament]);

  const isAdmin =
    tournament?.owner === user?.id ||
    tournament?.admins?.includes(user?.id!) ||
    (user?.roles && user.roles.includes("TournamentAdmin"))
      ? true
      : false;

  const tournamentWithGroups =
    tournament?.groups && tournament.groups.length > 0;

  const changeMatches = () => {
    setLoading(true);
    setMatches([]);

    if (!showMyMatches) {
      MatchService.getMatchsWithQuery({
        userId: user?.id,
        tournamentId: tournament?.id,
      }).then((response) => {
        const myMatches: Array<IMatch> = response.data.data.matches;

        let filtered;
        filtered = tournament?.matches.filter((match: IMatchTournamentSeed) =>
          myMatches.find((myMatch: IMatch) => myMatch.id === match.matchesId[0])
        );

        setMatches(filtered);
        setLoading(false);
      });
    } else {
      setMatches(tournament?.matches);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }

    setShowMyMatches(!showMyMatches);
  };

  const filterMatchNumber = (event: any) => {
    setLoading(true);
    setMatches([]);
    let { value } = event.target;

    const matchNumber = value;
    setFilterNumber(value);

    setTimeout(() => {
      if (matchNumber) {
        const matchesList = tournament?.matches.filter(
          (match: IMatchTournamentSeed) => match.id === matchNumber
        );

        setMatches(matchesList);
      } else {
        setMatches(tournament?.matches);
      }

      setLoading(false);
    }, 300);
    setFilterNumber(matchNumber);
  };

  const closeMatchModal = () => {
    /*if(tournament){
      TournamentService.getTournament(tournament?.id).then((t) => {
        props.loadTournament(t);
        setShowMatchModal({ show: false, match: undefined });
      })
    } else {*/
    setShowMatchModal({ show: false, match: undefined });
    //}
  };

  return (
    <>
      <TournamentMatchesContext.Provider value={providerTournamentMatches}>
        {/* TABS HEADER */}
        <div className="container tabs-header">
          <div className="columns">
            <div className="column col-12">
              <ul className="tab skp-text-light">
                {tabs
                  .filter((tab) =>
                    tournament?.playerType === 0 ? tab.url !== "teams" : tab
                  )
                  .filter((tab) =>
                    tournament?.announcements?.length === 0
                      ? tab.url !== "announcements"
                      : tab
                  )
                  .filter((tab) =>
                    tournament?.groups?.length === 0
                      ? tab.url !== "groups"
                      : tab
                  )
                  .filter((tab) =>
                    tournament?.groups?.length !== 0
                      ? tab.url !== "brackets"
                      : tab
                  )
                  .filter((tab) =>
                    tournament?.groups?.length !== 0
                      ? tab.url !== "matches"
                      : tab
                  )
                  .map((tab) => (
                    <li
                      key={tab.name}
                      className={`${
                        urlParams.tab === tab.url
                          ? "tab-item active"
                          : "tab-item"
                      } ${loading && "tab-item-disabled"}`}
                      onClick={() => clickLink(tab.url)}
                    >
                      <Link
                        to={`/tournaments/${tournament?.id}/${tab.url}`}
                        className={`${
                          urlParams.tab === tab.url ? "active" : null
                        } ${
                          tab.url === "announcements" && announcs > 0 && "badge"
                        }
                          ${tab.url === "chat" && chatToRead > 0 && "badge"}`}
                        data-badge={
                          tab.url === "announcements" ? announcs : chatToRead
                        }
                      >
                        {tab.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        {/* END TABS HEADER */}
        {/* TABS BODY */}
        <div className="container tabs-body">
          <div className="columns">
            {urlParams.tab === tabs[0].url && (
              <OverviewTab
                tournament={tournament}
                loadTournament={props.loadTournament}
                keyValue={"description"}
                title={t("titles.information")}
              />
            )}
            {urlParams.tab === tabs[1].url && (
              <OverviewTab
                tournament={tournament}
                loadTournament={props.loadTournament}
                keyValue="rules"
                title={t("tabs.rules")}
              />
            )}

            {urlParams.tab === tabs[2].url && (
              <>
                {tournament && (
                  <GroupsTab
                    tournament={tournament}
                    onSetMatch={(match: IMatchTournamentSeed) =>
                      setShowMatchModal({ show: true, match: match })
                    }
                    isAdmin={isAdmin}
                    loadTournament={props.loadTournament}
                    showMatchDetailModal={showMatchModal.show}
                  />
                )}
              </>
            )}

            {urlParams.tab === tabs[3].url && (
              <>
                {tournament?.members.includes(user?.id!) && (
                  <>
                    <div className="space-divider-20"></div>
                    <div
                      className="column col-12 flex-centered"
                      style={{ justifyContent: "right" }}
                    >
                      <button
                        className="skp-btn skp-btn-secondary"
                        onClick={() => changeMatches()}
                      >
                        {showMyMatches
                          ? t("tournament.showAllMatches")
                          : t("tournament.showMyMatches")}
                      </button>
                    </div>
                  </>
                )}

                {!loading &&
                  !loadingMatches &&
                  tournament &&
                  tournament.groups?.length === 0 && (
                    <BracketsTab
                      loadTournament={props.loadTournament}
                      tournament={tournament}
                      matches={matches}
                      onLogin={() => setShowLoginModal(true)}
                      onSetMatch={(match: IMatchTournamentSeed) =>
                        setShowMatchModal({ show: true, match: match })
                      }
                    />
                  )}
                {(loading || loadingMatches) && (
                  <div className="column col-12 text-center">
                    <div className="space-divider"></div>
                    <div className="loading"></div>
                  </div>
                )}
              </>
            )}
            {urlParams.tab === tabs[4].url && (
              <>
                {tournament?.members.includes(user?.id!) && (
                  <>
                    <div className="space-divider-20"></div>
                    <div
                      className="column col-12 flex-centered"
                      style={{ justifyContent: "right" }}
                    >
                      <button
                        className="skp-btn skp-btn-secondary"
                        onClick={() => changeMatches()}
                      >
                        {showMyMatches
                          ? t("tournament.showAllMatches")
                          : t("tournament.showMyMatches")}
                      </button>
                    </div>
                  </>
                )}

                {!loading && !showMatchModal.show && (
                  <MatchesTab
                    matches={matches}
                    onLogin={() => setShowLoginModal(true)}
                    tournament={tournament}
                    onSetMatch={(match: IMatchTournamentSeed) =>
                      setShowMatchModal({ show: true, match: match })
                    }
                  />
                )}
                {loading && (
                  <div className="column col-12 text-center">
                    <div className="space-divider"></div>
                    <div className="loading"></div>
                  </div>
                )}
              </>
            )}
            {urlParams.tab === tabs[5].url && (
              <PlayerTab
                tournament={tournament}
                onLogin={() => setShowLoginModal(true)}
                onReloadTournament={props.loadTournament}
              />
            )}
            {urlParams.tab === tabs[6].url && tournament?.playerType !== 0 && (
              <TeamsTab
                tournament={tournament}
                userStatus={userStatus}
                onReloadTournament={props.loadTournament}
              />
            )}
            {urlParams.tab === tabs[7].url && (
              <ResultsTab tournament={tournament} />
            )}
            {urlParams.tab === tabs[8].url && (
              <AnnouncementsTab
                tournament={tournament}
                disableTextEditor={true}
                onReloadTournament={props.loadTournament}
              ></AnnouncementsTab>
            )}
            {urlParams.tab === tabs[9].url && (
              <ChatTab tournament={tournament} isAdmin={isAdmin}></ChatTab>
            )}
          </div>
        </div>
        {/* END TABS BODY */}

        <Modal
          show={showLoginModal}
          size="small"
          onClose={() => setShowLoginModal(false)}
        >
          <LoginOrRegisterModal
            message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
            onClose={() => setShowLoginModal(false)}
          />
        </Modal>

        <Modal
          show={showMatchModal.show}
          onClose={() => closeMatchModal()}
          title={`Match ${showMatchModal.match?.id}`}
        >
          <>
            {/* Single Match */}
            {tournament &&
              showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length === 1 && (
                <MatchDetailsModal
                  tournament={tournament}
                  matchId={showMatchModal.match?.matchesId[0]}
                  closeModal={closeMatchModal}
                  onReloadTournament={props.loadTournament}
                  updateNotification={updateNotification}
                />
              )}
            {/* Multi Match */}
            {showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length > 1 && (
                <MultiMatchDetailsModal
                  match={showMatchModal.match}
                  tournament={tournament}
                  updateNotification={updateNotification}
                />
              )}
          </>
        </Modal>
      </TournamentMatchesContext.Provider>
    </>
  );
};

export default TournamentWithBrackets;
