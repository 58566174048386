import { useState, useContext } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import UserService from "../../../services/UserService";
import { UserContext } from "../../Context/UserContext";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ErrorLabel from "../../Forms/ErrorLabel";
import ITournament from "../../Interfaces/Tournament";
import IGameAccount from "../../Interfaces/GameAccount";
import GAMES from "../../../constants-games";
import IUserDetails from "../../Interfaces/UserDetails";

type Props = {
  tournament: ITournament;
  selectUser?: IUserDetails;
  closeModal: () => void;
  onReloadTournament?: (tournament?: ITournament) => void;
  onAccountAdded?: (gameAccount: IGameAccount) => void; // Nuova prop aggiunta per callback
};

type FormModel = {
  username: string;
  game: string;
  console: string;
  id: string;
};

const AddSingleGameAccountModal = (props: Props) => {
  const { tournament, selectUser } = props;
  const [loading, setLoading] = useState(false);
  let { user, setUser } = useContext(UserContext);

  user = selectUser ? selectUser : user;

  const idRequest =
    tournament?.game === GAMES.CLASH_ROYALE.ENUM ||
    tournament?.game === GAMES.PUBG_MOBILE.ENUM;

  const initialValues = {
    username: "",
    game: tournament?.game,
    console: tournament?.console[0],
    id: "",
  };
  const initValidationSchema = Yup.object({
    username: Yup.string().required("Required!"),
    game: Yup.string().required("Required!"),
    console: Yup.string().required("Required!"),
  });

  const idSchemaObject = Yup.object({
    id: Yup.string().required("Required!"),
  });

  const validationSchema = idRequest
    ? initValidationSchema.concat(idSchemaObject)
    : initValidationSchema;

  const onSubmit = (values: FormModel) => {
    let gameAccounts: Array<IGameAccount> = [];
    if (user?.gameAccounts && user?.gameAccounts?.length > 0) {
      gameAccounts = [...user?.gameAccounts];
    }
    gameAccounts.push(values);
    setLoading(true);
    const userId = user?.id;
    UserService.updateUserInfo({ gameAccounts: gameAccounts }, userId)
      .then((user) => {
        setLoading(false);
        if (!selectUser) {
          setUser(user);
        }

        props.closeModal();
        toast.success("User information updated");

        // Chiama il callback per aggiungere il nuovo game account
        if (props.onAccountAdded) {
          props.onAccountAdded(values);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <div className="space-divider-20"></div>
      <h6 className="skp-text-primary">
        Please provide a game account for {GAMES[tournament?.game].NAME}
      </h6>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <div className="columns">
              <div className="column col-xs-12">
                <div className="skp-form-control">
                  <label className="skp-text-light skp-text-small">
                    Username
                  </label>
                  <Field type="text" name="username" />
                  <ErrorMessage name={`username`} component={ErrorLabel} />
                </div>
              </div>

              {idRequest && (
                <div className="column col-xs-12">
                  <div className="skp-form-control">
                    <label className="skp-text-light skp-text-small">
                      {tournament?.game === GAMES.PUBG_MOBILE.ENUM && "UID"}
                      {tournament?.game === GAMES.CLASH_ROYALE.ENUM && "ID/TAG"}
                    </label>
                    <Field type="text" name="id" />
                    <ErrorMessage name={`id`} component={ErrorLabel} />
                  </div>
                </div>
              )}

              <div className="column col-xs-12">
                <div className="skp-form-control">
                  <label className="skp-text-light skp-text-small">
                    Platform
                  </label>
                  <Field as="select" name="console">
                    {tournament?.console.map((option, id) => {
                      return (
                        <option key={`${option}`} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage name="console" component={ErrorLabel} />
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column col-12 text-right">
                <div className="space-divider"></div>
                <button
                  type="submit"
                  disabled={!isValid || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Save"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSingleGameAccountModal;
