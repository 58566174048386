import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import TournamentService from "../../../../services/TournamentService";
import ITournament from "../../../Interfaces/Tournament";
import LeaderboardTab from "./Tabs/LeaderboardTab";
import "./play-play.scss";
import PlayersTab from "./Tabs/PlayersTab";
import TeamsTab from "./Tabs/TeamsTab";
import YuniteMatchesTab from "./Tabs/YuniteMatchesTab";
import { isMobile } from "react-device-detect";
import ClashRoyaleLeaderboardTab from "./Tabs/ClashRoyaleLeaderboardTab";
import BracketsTab from "./Tabs/BracketsTab";
import { UserContext } from "../../../Context/UserContext";
import UserService from "../../../../services/UserService";
import GAMES from "../../../../constants-games";
import LobbiesTab from "./Tabs/LobbiesTab";
import PointSystemTab from "../../Tournaments/TournamentTabs/ApexTabs/PointSystemTab";
import Scrollbars from "react-custom-scrollbars";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PlayPlayTournament = () => {
  const { user, setUser } = useContext(UserContext);
  const [tournament, setTournament] = useState<ITournament>();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("players");

  let query = useQuery();
  let { tournamentId } = useParams<{ tournamentId: string }>();

  const delay = Number(query.get("refresh")) || 100000;
  const authKey = query.get("authKey");

  const loadTournament = useCallback(
    (tournament?: ITournament) => {
      if (tournament) {
        setTournament(tournament);
        if (
          tournament?.game &&
          GAMES[tournament?.game]?.CONTROL_TYPE === "lobby"
        )
          setTab("teams");
      } else {
        setLoading(true);
        if (tournamentId) {
          TournamentService.getTournament(tournamentId).then((tournament) => {
            setTournament(tournament);
            if (tournament?.game === GAMES.CLASH_ROYALE.ENUM) {
            } else if (
              tournament?.game &&
              GAMES[tournament?.game]?.CONTROL_TYPE === "lobby"
            ) {
              setTab("teams");
            }
            setLoading(false);
          });
        }
      }
    },
    [tournamentId]
  );

  useEffect(() => {
    let timer1 = setInterval(() => {
      loadTournament();
    }, delay);

    loadTournament();

    if (authKey) {
      UserService.getUserByAuthKey(authKey).then((response) => {
        setUser(response);
      });
    }

    return () => {
      clearTimeout(timer1);
      setUser(null);
    };
  }, [loadTournament, setUser, delay, authKey]);

  return (
    <>
      {tournament?.game &&
        GAMES[tournament?.game]?.CONTROL_TYPE === "bracket" && (
          <>
            <div className="play-tabs-wrapper p-2 show-xs">
              <select
                onChange={(event) => setTab(event.target.value)}
                value={tab}
              >
                <option value="players">Players</option>
                {tournament?.playerType !== 0 && (
                  <option value="teams">Teams</option>
                )}
                <option value="bracket">Bracket</option>
              </select>
            </div>

            <div className="play-tabs-wrapper hide-xs">
              <div
                className={`play-tab flex-centered ${
                  tab === "players" ? "active" : ""
                } `}
                onClick={() => setTab("players")}
              >
                Players
              </div>
              {tournament?.playerType !== 0 && (
                <div
                  className={`play-tab flex-centered ${
                    tab === "teams" ? "active" : ""
                  } `}
                  onClick={() => setTab("teams")}
                >
                  Teams
                </div>
              )}

              <div
                className={`play-tab flex-centered ${
                  tab === "bracket" ? "active" : ""
                } `}
                onClick={() => setTab("bracket")}
              >
                Bracket
              </div>
            </div>

            {tab === "players" && <PlayersTab tournament={tournament} />}
            {tab === "teams" && tournament?.playerType !== 0 && (
              <TeamsTab tournament={tournament} />
            )}
            {tab === "bracket" && <BracketsTab tournament={tournament} />}
          </>
        )}
      {tournament?.game &&
        GAMES[tournament?.game]?.CONTROL_TYPE === "lobby" &&
        tournament?.game !== GAMES.CLASH_ROYALE.ENUM && (
          <>
            <div className="play-tabs-wrapper p-2 show-xs">
              <select
                onChange={(event) => setTab(event.target.value)}
                value={tab}
              >
                <option value="teams">Teams</option>
                <option value="players">Players</option>
                <option value="lobbies">Lobbies</option>
                <option value="point">Point System</option>
              </select>
            </div>

            <div className="play-tabs-wrapper hide-xs">
              <div
                className={`play-tab flex-centered ${
                  tab === "teams" ? "active" : ""
                } `}
                onClick={() => setTab("teams")}
              >
                Teams
              </div>
              <div
                className={`play-tab flex-centered ${
                  tab === "players" ? "active" : ""
                } `}
                onClick={() => setTab("players")}
              >
                Players
              </div>
              <div
                className={`play-tab flex-centered ${
                  tab === "lobbies" ? "active" : ""
                } `}
                onClick={() => setTab("lobbies")}
              >
                Lobbies
              </div>
              <div
                className={`play-tab flex-centered ${
                  tab === "point" ? "active" : ""
                } `}
                onClick={() => setTab("point")}
              >
                Point System
              </div>
            </div>

            {tab === "lobbies" && (
              <Scrollbars style={{ width: "100%", height: "90%" }}>
                <LobbiesTab tournament={tournament}></LobbiesTab>
              </Scrollbars>
            )}
            {tab === "players" && <PlayersTab tournament={tournament} />}
            {tab === "teams" && tournament && (
              <Scrollbars style={{ width: "100%", height: "90%" }}>
                <TeamsTab tournament={tournament} />
              </Scrollbars>
            )}
            {tab === "point" && (
              <Scrollbars style={{ width: "100%", height: "90%" }}>
                <PointSystemTab tournament={tournament}></PointSystemTab>
              </Scrollbars>
            )}
          </>
        )}
      {tournament?.game === GAMES.CLASH_ROYALE.ENUM && (
        <>
          <div className="play-tabs-wrapper p-2 show-xs">
            <select
              onChange={(event) => setTab(event.target.value)}
              value={tab}
            >
              <option value="players">Players</option>
              <option value="leaderboard">Leaderboard</option>
            </select>
          </div>

          <div className="play-tabs-wrapper hide-xs">
            <div
              className={`play-tab flex-centered ${
                tab === "players" ? "active" : ""
              } `}
              onClick={() => setTab("players")}
            >
              Players
            </div>

            <div
              className={`play-tab flex-centered ${
                tab === "leaderboard" ? "active" : ""
              } `}
              onClick={() => setTab("leaderboard")}
            >
              Leaderboard
            </div>
          </div>

          {tab === "leaderboard" && (
            <ClashRoyaleLeaderboardTab
              tournament={tournament}
            ></ClashRoyaleLeaderboardTab>
          )}
          {tab === "players" && <PlayersTab tournament={tournament} />}
        </>
      )}
      {tournament?.game === GAMES.FORTNITE.ENUM && (
        <>
          <div className="play-tabs-wrapper p-2 show-xs">
            <select
              onChange={(event) => setTab(event.target.value)}
              value={tab}
            >
              <option value="players">Players</option>
              <option value="teams">Teams</option>
              <option value="leaderboard">Leaderboard</option>
              <option value="matches">Matches</option>
            </select>
          </div>

          <div className="play-tabs-wrapper hide-xs">
            <div
              className={`play-tab flex-centered ${
                tab === "players" ? "active" : ""
              } `}
              onClick={() => setTab("players")}
            >
              Players
            </div>
            <div
              className={`play-tab flex-centered ${
                tab === "teams" ? "active" : ""
              } `}
              onClick={() => setTab("teams")}
            >
              Teams
            </div>
            <div
              className={`play-tab flex-centered ${
                tab === "leaderboard" ? "active" : ""
              } `}
              onClick={() => setTab("leaderboard")}
            >
              Leaderboard
            </div>
            <div
              className={`play-tab flex-centered ${
                tab === "matches" ? "active" : ""
              } `}
              onClick={() => setTab("matches")}
            >
              Matches
            </div>
          </div>

          {tab === "leaderboard" && (
            <LeaderboardTab yuniteId={tournament?.yuniteId} />
          )}
          {tab === "players" && <PlayersTab tournament={tournament} />}
          {tab === "teams" && tournament && (
            <TeamsTab tournament={tournament} />
          )}
          {tab === "matches" && tournament && (
            <YuniteMatchesTab tournament={tournament} />
          )}
        </>
      )}
    </>
  );
};

export default PlayPlayTournament;
